<template>
  <div
    v-bar="{ preventParentScroll: true, scrollThrottle: 30 }"
    :style="sidebarStyle"
    class="sidebar d-flex flex-column"
    el1ScrollVisibleClass>

    <div class="sidebar-wrapper">
      <div class="logo py-4">
        <nuxt-link
          to="/"
          class="simple-text">
          <div class="logo-img">
            <img
              :src="company ? rootDomain + company + '_menu.png' : rootDomain + 'logo_unidade.png'"
              alt="">
          </div>
        </nuxt-link>
      </div>
      <slot name="content"/>
      <ul
        id="sidebar"
        class="nav flex-column">
        <nuxt-link
          v-for="(link,index) in sidebarLinks"
          :id="link.id"
          :to="link.to"
          :key="index"
          tag="li"
          class="nav-link nt-item-link">
          <div class="d-flex flex-row align-items-center">
            <i :class="[link.icon, 'color-secondary']"/>
            <p class="w-75">{{ link.title }}</p>
          </div>
        </nuxt-link>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      backgroundColor: {
        type: String,
        default: 'black',
        validator: (value) => {
          let acceptedValues = ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      backgroundImage: {
        type: String,
        default: ''
      },
      activeColor: {
        type: String,
        default: 'success',
        validator: (value) => {
          let acceptedValues = ['primary', 'info', 'success', 'warning', 'danger']
          return acceptedValues.indexOf(value) !== -1
        }
      },
      sidebarLinks: {
        type: Array,
        default: () => []
      },
      autoClose: {
        type: Boolean,
        default: true
      }
    },
    provide () {
      return {
        autoClose: this.autoClose
      }
    },
    computed: {
      sidebarStyle () {
        return {
          backgroundImage: `url(${this.backgroundImage})`
        }
      },
      company () {
        return process.env.company;
      },
      rootDomain () {
        return process.env.rootDomain;
      }
    }
  }
</script>
