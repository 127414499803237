import { Config } from '@/services/config.js'

// Estado geral da aplicação
const state = () => {
  return {
    default_options: {}
  }
}

const getters = {
  getAllDefaultOptions (state) {
    return state.default_options
  },
  blockedModules (state) {
    return state.default_options['modulos']
  },
  teachingUnitGender (state) {
    return state.default_options['genero']
  }
}

const mutations = {
  setDefaultOptions (state, options) {
    state.default_options = options
  },
  clearDefaultOptions (state) {
    state.default_options = {}
  }
}

const actions = {
  getDefaultOptions (context) {
    let defaultOptions = {
      'unidade_info': {
        'name': 'unidade_info',
        'indices': [
          'genero',
          'nome',
          'logo_instituicao',
          'telefone_principal',
          'telefone_whatsapp',
          'endereco',
          'endereco_string',
          'cnpj',
          'razao_social'
        ]
      },
      'shortcut_icon_site': {
        'name': 'shortcut_icon_site',
        'indices': []
      },
      'aluno_config': {
        'name': 'aluno_config',
        'indices': [
          'cadastro'
        ]
      },
      'client_blocked_modules': {
        'name': 'client_blocked_modules',
        'indices': ['modulos']
      },
      'primeiro_acesso': {
        'name': 'primeiro_acesso'
      },
      'pagarme_encripted_key': {
        'name': 'pagarme_encripted_key'
      },
      'default_theme': {
        'name': 'default_theme'
      },
      'boleto_configurado': {
        'name': 'boleto_configurado'
      },
      'enable_labs': {
        'name': 'enable_labs'
      },
      'codigo_extra_site': {
        'name': 'codigo_extra_site'
      }
    }

    return Config.getDefaultConfigs(defaultOptions)
      .then((response) => {
        context.commit('setDefaultOptions', response.data)
        if(response.data.default_theme) {
          context.commit('app/changeTheme', response.data.default_theme, { root: true })
        }
        return response
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
