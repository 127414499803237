<template>
  <nav class="navbar p-0 px-3">
    <nuxt-link
      class="d-lg-none"
      to="/">
      <img
        :src="company ? rootDomain + company + '_mobile.png' : rootDomain + 'logo_unidade_mobile.png'"
        alt=""
        class="img-logo-navbar">
    </nuxt-link>
    <a class="navbar-brand text-capitalize">
      {{ pageTitle }}
      <i
        id="page-tutorial-icon"
        class="fa fa-question-circle-o mr-1"
        @click="playPageTutorial"
      />
    </a>
    <button
      class="navbar-toggler nt-invisible-lg"
      type="button"
      aria-controls="navbar-index"
      aria-expanded="false"
      aria-label="Toggle navigation"
      @click.stop="toggleSidebar">

      <span class="nt-navbar-toggler-icon"><i class="fa fa-bars"/></span>
    </button>

    <div class="nt-invisible-md flex-grow-1">
      <ul class="navbar-nav flex-row flex-grow-1 justify-content-end align-items-center">
        <li class="nav-item">
          <b-dropdown
            class="nt-themes"
            variant="link">
            <template slot="button-content">
              <i class="fa fa-paint-brush" />
            </template>
            <b-dropdown-item @click="changeTheme('osiris')">
              <div class="d-flex justify-content-between align-items-center">
                Light<span class="ml-2 preview-colors-osiris"/>
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="changeTheme('seth')">
              <div class="d-flex justify-content-between align-items-center">
                Dark<span class="ml-2 preview-colors-seth"/>
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="changeTheme('horus')">
              <div class="d-flex justify-content-between align-items-center">
                Gray<span class="ml-2 preview-colors-horus"/>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </li>
        <li class="nav-item ma-1">
          Olá<span
            v-if="firstName"
            class="text-capitalize">{{ ', ' + firstName }}!</span>
        </li>
        <li class="nav-item ma-1">
          <b-dropdown
            text="Dropdown Button"
            no-caret
            right
            variant="link">
            <template slot="button-content">
              <img
                :src="avatar"
                class="rounded-circle"
                style="width: auto; height: 40px;"
                alt="avatar">
            </template>
            <b-dropdown-item :to="{ name: 'aluno-perfil-informacoes-pessoais' }">
              <i class="fa fa-user mr-1"/>
              <span>Meu Perfil</span>
            </b-dropdown-item>
            <b-dropdown-item @click="$emit('tutorial')">
              <i class="fa fa-question-circle-o mr-1"/>
              <span>Conhecer a Plataforma</span>
            </b-dropdown-item>
            <b-dropdown-divider/>
            <b-dropdown-item @click="$emit('logout')">
              <i class="fa fa-power-off mr-1"/>
              <span>Sair</span>
            </b-dropdown-item>
          </b-dropdown>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      isClassroom: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: function () {
      return {
        activeNotifications: false,
        sidebar: {
          showSidebar: false
        }
      }
    },
    computed: {
      routeName: function () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      },
      firstName: function () {
        if (this.name) {
          return this.name.split(' ')[0]
        }
      },
      ...mapGetters({
        pageTitle: 'app/pageTitle',
        tutorial: 'app/currentTutorial',
        activeTheme: 'app/activeTheme',
        name: 'app/name',
        avatar: 'app/avatar'
      }),
      company () {
        return process.env.company;
      },
      rootDomain () {
        return process.env.rootDomain;
      }
    },
    methods: {
      capitalizeFirstLetter: function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown: function () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown: function () {
        this.activeNotifications = false
      },
      toggleSidebar: function () {
        this.$store.commit('app/toggleSidebar')
      },
      hideSidebar: function () {
        this.sidebar.displaySidebar(false)
      },
      changeTheme: function (themeName) {
        this.$store.commit('app/changeTheme', themeName)
      },
      previewTheme: function (themeName) {
        this.$store.commit('app/previewTheme', themeName)
      },
      playPageTutorial: function() {
        // Só permite que o tutorial seja iniciado se a sidebar mobile estiver fechada.
        if(!this.$store.state.app.sidebar.open) {
          if (this.tutorial !== null) {
            import('@/common/tutorials/' + this.tutorial)
            .then(module => {
              const tutorial = new module.default(this.$store, this.tutorial)
              tutorial.start()
            })
          }
        }
      }
    }
  }
</script>


<style lang="scss" scoped>
  @media (min-width: 412px) {
    .img-logo-navbar {
      height: 42px;
    }
  }
  @media (min-width: 330px) and (max-width: 411px) {
    .img-logo-navbar {
      height: 8vw;
    }
  }
  @media (max-width: 329px) {
    .img-logo-navbar {
      height: 7.5vw;
    }
  }
</style>
