import { axios } from '@/plugins/axios'

export class Exercise {

static getLessonAnsweredExercises(enrollmentId, lessonId) {
    return axios.get('/exercises/lesson/exercises', {
      params: {
        enrollment_id: enrollmentId,
        lesson_id: lessonId,
      }
    })
  }

static getAnswerFile(fileName) {
    return axios.get('/exercises/lesson/answer/file', {
      params: {
        file_name: fileName
      }
    })
  }
}
