import { Classroom } from '@/services/classroom'

function changeActiveLesson (treeData, lessonId) {
  if (treeData) {
    treeData.active = false
    if (treeData.hasOwnProperty('nodes')) {
      treeData.nodes.forEach(element => {
        element.active = false
        let retornoChildrens = changeActiveLesson(element, lessonId)
        treeData.active = treeData.active || retornoChildrens
      })
    } else {
      if (Number(lessonId) === treeData.id) {
        treeData.active = true
      }
    }
    return treeData.active
  }
}

function changeBlockedLesson (treeData, lessonId) {
  if (treeData.hasOwnProperty('nodes')) {
    treeData.nodes.forEach(element => {
      let retornoChildrens = changeBlockedLesson(element, lessonId)
      treeData.blocked = treeData.blocked ? retornoChildrens : treeData.blocked
    })
  } else {
    if (Number(lessonId) === treeData.id) {
      treeData.blocked = false
    }
  }
  return treeData.blocked
}

const state = () => {
  return {
    enrollment: null,
    activeLesson: {
      moduleId: null,
      topicId: null,
      lessonId: null
    },
    currentLesson: {
      moduleId: null,
      topicId: null,
      lessonId: null
    },
    syllabus: null,
    courseName: null
  }
}

const getters = {
  enrollmentId: function (state) {
    return state.enrollmentId
  },
  courseName: function (state) {
    return state.courseName
  },
  activeLesson: function (state) {
    return state.activeLesson
  },
  syllabus: function (state) {
    return state.syllabus
  }
}

const mutations = {
  changeEnrollment (state, value) {
    state.enrollmentId = value
  },
  updateSyllabus (state, value) {
    state.syllabus = value
  },
  changeCourseName (state, value) {
    state.courseName = value
  },
  changeCurrentLesson (state, value) {
    state.currentLesson = value
  },
  updateActiveLesson (state, lessonId) {
    changeActiveLesson(state.syllabus, lessonId)
  },
  unBlockLesson (state, lessonId) {
    changeBlockedLesson(state.syllabus, lessonId)
  }
}

const actions = {
  getEnrollmentData (context, enrollmentId) {
    return Classroom.getCourseSyllabus(enrollmentId)
      .then(response => {
        context.commit('changeCourseName', response.data.syllabus.nome_curso)
        context.commit('changeCurrentLesson', response.data.currentLesson)

        changeActiveLesson(response.data.syllabus, response.data.currentLesson.aula_id)

        context.commit('updateSyllabus', response.data.syllabus)

        return response
      }).catch(error => {
        throw error
      })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
