<template>
  <transition 
    name="fade" 
    mode="out-in">
    <nuxt/>
  </transition>
</template>

<script>
  export default {}
</script>

<style scoped>
  /* .fade-enter-active,
    .fade-leave-active {
      transition: opacity 1s
    }
    .fade-enter,
    .fade-leave-to */
      /* .fade-leave-active in <2.1.8 */
    /* {
      opacity: 0
    } */
</style>

