import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ce4151de = () => interopDefault(import('../pages/anotacoes.vue' /* webpackChunkName: "pages/anotacoes" */))
const _0ad88cd4 = () => interopDefault(import('../pages/anotacoes/index.vue' /* webpackChunkName: "pages/anotacoes/index" */))
const _1a8f0dc0 = () => interopDefault(import('../pages/anotacoes/_enrollmentId.vue' /* webpackChunkName: "pages/anotacoes/_enrollmentId" */))
const _215f8acb = () => interopDefault(import('../pages/avisos.vue' /* webpackChunkName: "pages/avisos" */))
const _ff24e5e4 = () => interopDefault(import('../pages/avisos/index.vue' /* webpackChunkName: "pages/avisos/index" */))
const _67a80868 = () => interopDefault(import('../pages/avisos/_alertId.vue' /* webpackChunkName: "pages/avisos/_alertId" */))
const _77feb9d1 = () => interopDefault(import('../pages/biblioteca/index.vue' /* webpackChunkName: "pages/biblioteca/index" */))
const _d5ba93ae = () => interopDefault(import('../pages/eventos/index.vue' /* webpackChunkName: "pages/eventos/index" */))
const _733a7cac = () => interopDefault(import('../pages/exerciciosrespondidos.vue' /* webpackChunkName: "pages/exerciciosrespondidos" */))
const _7a2c5ba2 = () => interopDefault(import('../pages/exerciciosrespondidos/index.vue' /* webpackChunkName: "pages/exerciciosrespondidos/index" */))
const _3008337b = () => interopDefault(import('../pages/exerciciosrespondidos/_enrollmentId.vue' /* webpackChunkName: "pages/exerciciosrespondidos/_enrollmentId" */))
const _0cf4a6d2 = () => interopDefault(import('../pages/exerciciosrespondidos/_enrollmentId/exercicios/_lessonId.vue' /* webpackChunkName: "pages/exerciciosrespondidos/_enrollmentId/exercicios/_lessonId" */))
const _6a67c9c9 = () => interopDefault(import('../pages/forum.vue' /* webpackChunkName: "pages/forum" */))
const _ec9c6fde = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _b162a4ae = () => interopDefault(import('../pages/matricula/index.vue' /* webpackChunkName: "pages/matricula/index" */))
const _8c58e782 = () => interopDefault(import('../pages/matricular/index.vue' /* webpackChunkName: "pages/matricular/index" */))
const _49b11f8c = () => interopDefault(import('../pages/matricular-sucesso.vue' /* webpackChunkName: "pages/matricular-sucesso" */))
const _33a69ffc = () => interopDefault(import('../pages/mensalidades.vue' /* webpackChunkName: "pages/mensalidades" */))
const _f73b8d02 = () => interopDefault(import('../pages/mensalidades/index.vue' /* webpackChunkName: "pages/mensalidades/index" */))
const _50e76a6a = () => interopDefault(import('../pages/mensalidades/_enrollmentId.vue' /* webpackChunkName: "pages/mensalidades/_enrollmentId" */))
const _b90cde14 = () => interopDefault(import('../pages/meus-cursos.vue' /* webpackChunkName: "pages/meus-cursos" */))
const _548b58ed = () => interopDefault(import('../pages/notas.vue' /* webpackChunkName: "pages/notas" */))
const _9cca8ca0 = () => interopDefault(import('../pages/notas/index.vue' /* webpackChunkName: "pages/notas/index" */))
const _3543ec08 = () => interopDefault(import('../pages/notas/_enrollmentId.vue' /* webpackChunkName: "pages/notas/_enrollmentId" */))
const _04a0d151 = () => interopDefault(import('../pages/pagamento/index.vue' /* webpackChunkName: "pages/pagamento/index" */))
const _132c044c = () => interopDefault(import('../pages/primeiro-acesso.vue' /* webpackChunkName: "pages/primeiro-acesso" */))
const _0c676e81 = () => interopDefault(import('../pages/prova-virtual/index.vue' /* webpackChunkName: "pages/prova-virtual/index" */))
const _16b155e3 = () => interopDefault(import('../pages/provas.vue' /* webpackChunkName: "pages/provas" */))
const _08deb3b4 = () => interopDefault(import('../pages/provas/index.vue' /* webpackChunkName: "pages/provas/index" */))
const _4f99bf1c = () => interopDefault(import('../pages/provas/_enrollmentId.vue' /* webpackChunkName: "pages/provas/_enrollmentId" */))
const _0fae0975 = () => interopDefault(import('../pages/provas/_enrollmentId/index.vue' /* webpackChunkName: "pages/provas/_enrollmentId/index" */))
const _8e96c95e = () => interopDefault(import('../pages/provas/_enrollmentId/_schedulingId.vue' /* webpackChunkName: "pages/provas/_enrollmentId/_schedulingId" */))
const _225ef8f8 = () => interopDefault(import('../pages/saladeaula/index.vue' /* webpackChunkName: "pages/saladeaula/index" */))
const _cfd7e788 = () => interopDefault(import('../pages/vestibular/index.vue' /* webpackChunkName: "pages/vestibular/index" */))
const _2401ad78 = () => interopDefault(import('../pages/aluno/perfil.vue' /* webpackChunkName: "pages/aluno/perfil" */))
const _9a2ba9da = () => interopDefault(import('../pages/aluno/perfil/contato.vue' /* webpackChunkName: "pages/aluno/perfil/contato" */))
const _7f38fb33 = () => interopDefault(import('../pages/aluno/perfil/informacoes-pessoais.vue' /* webpackChunkName: "pages/aluno/perfil/informacoes-pessoais" */))
const _0f1a5c31 = () => interopDefault(import('../pages/aluno/perfil/redefinir-senha.vue' /* webpackChunkName: "pages/aluno/perfil/redefinir-senha" */))
const _5ceec6ca = () => interopDefault(import('../pages/biblioteca/curso.vue' /* webpackChunkName: "pages/biblioteca/curso" */))
const _47e7695b = () => interopDefault(import('../pages/biblioteca/curso/_cursoId/_page.vue' /* webpackChunkName: "pages/biblioteca/curso/_cursoId/_page" */))
const _5134f91a = () => interopDefault(import('../pages/biblioteca/labs/index.vue' /* webpackChunkName: "pages/biblioteca/labs/index" */))
const _120cbe9f = () => interopDefault(import('../pages/matricula/pre-cadastro/index.vue' /* webpackChunkName: "pages/matricula/pre-cadastro/index" */))
const _4b96e99d = () => interopDefault(import('../pages/matricula/pre-cadastro/confirmacao.vue' /* webpackChunkName: "pages/matricula/pre-cadastro/confirmacao" */))
const _7edd272e = () => interopDefault(import('../pages/matricula/pre-cadastro/email.vue' /* webpackChunkName: "pages/matricula/pre-cadastro/email" */))
const _39df8051 = () => interopDefault(import('../pages/matricula/pre-cadastro/finalizar-cadastro.vue' /* webpackChunkName: "pages/matricula/pre-cadastro/finalizar-cadastro" */))
const _48973c94 = () => interopDefault(import('../pages/matricula/pre-cadastro/login.vue' /* webpackChunkName: "pages/matricula/pre-cadastro/login" */))
const _c254c794 = () => interopDefault(import('../pages/biblioteca/digital/_page.vue' /* webpackChunkName: "pages/biblioteca/digital/_page" */))
const _035db81e = () => interopDefault(import('../pages/biblioteca/labs/_labId.vue' /* webpackChunkName: "pages/biblioteca/labs/_labId" */))
const _58f20539 = () => interopDefault(import('../pages/biblioteca/publicacao/_tipo/_id.vue' /* webpackChunkName: "pages/biblioteca/publicacao/_tipo/_id" */))
const _5e1a08e2 = () => interopDefault(import('../pages/eventos/_eventId.vue' /* webpackChunkName: "pages/eventos/_eventId" */))
const _c82ff868 = () => interopDefault(import('../pages/matricula/_courseId.vue' /* webpackChunkName: "pages/matricula/_courseId" */))
const _0844fd62 = () => interopDefault(import('../pages/matricula/_courseId/index.vue' /* webpackChunkName: "pages/matricula/_courseId/index" */))
const _0bd8bccd = () => interopDefault(import('../pages/matricula/_courseId/_teachingUnit.vue' /* webpackChunkName: "pages/matricula/_courseId/_teachingUnit" */))
const _13ad8f20 = () => interopDefault(import('../pages/matricula/_courseId/_teachingUnit/informacoes.vue' /* webpackChunkName: "pages/matricula/_courseId/_teachingUnit/informacoes" */))
const _4a659c65 = () => interopDefault(import('../pages/matricular/_courseId/index.vue' /* webpackChunkName: "pages/matricular/_courseId/index" */))
const _3766f09d = () => interopDefault(import('../pages/pagamento/_enrollmentId.vue' /* webpackChunkName: "pages/pagamento/_enrollmentId" */))
const _78d77964 = () => interopDefault(import('../pages/recuperar-senha/_message.vue' /* webpackChunkName: "pages/recuperar-senha/_message" */))
const _1cdb5269 = () => interopDefault(import('../pages/resetar-senha/_token.vue' /* webpackChunkName: "pages/resetar-senha/_token" */))
const _469afd78 = () => interopDefault(import('../pages/saladeaula/_enrollmentId.vue' /* webpackChunkName: "pages/saladeaula/_enrollmentId" */))
const _4c08d447 = () => interopDefault(import('../pages/saladeaula/_enrollmentId/aula/index.vue' /* webpackChunkName: "pages/saladeaula/_enrollmentId/aula/index" */))
const _5f332272 = () => interopDefault(import('../pages/saladeaula/_enrollmentId/aula/_lessonId.vue' /* webpackChunkName: "pages/saladeaula/_enrollmentId/aula/_lessonId" */))
const _e7bef7ec = () => interopDefault(import('../pages/saladeaula/_enrollmentId/aula/_lessonId/index.vue' /* webpackChunkName: "pages/saladeaula/_enrollmentId/aula/_lessonId/index" */))
const _6750bbbc = () => interopDefault(import('../pages/saladeaula/_enrollmentId/aula/_lessonId/exerciciosrespondidos.vue' /* webpackChunkName: "pages/saladeaula/_enrollmentId/aula/_lessonId/exerciciosrespondidos" */))
const _d45fc5da = () => interopDefault(import('../pages/saladeaula/_enrollmentId/aula/_lessonId/_file.vue' /* webpackChunkName: "pages/saladeaula/_enrollmentId/aula/_lessonId/_file" */))
const _194ff7bc = () => interopDefault(import('../pages/vestibular/_courseId/index.vue' /* webpackChunkName: "pages/vestibular/_courseId/index" */))
const _4fb5b238 = () => interopDefault(import('../pages/prova-virtual/_examRequestId/finished.vue' /* webpackChunkName: "pages/prova-virtual/_examRequestId/finished" */))
const _f5446bc0 = () => interopDefault(import('../pages/prova-virtual/_examRequestId/info.vue' /* webpackChunkName: "pages/prova-virtual/_examRequestId/info" */))
const _60a7f760 = () => interopDefault(import('../pages/prova-virtual/_examRequestId/start.vue' /* webpackChunkName: "pages/prova-virtual/_examRequestId/start" */))
const _2fdeec9e = () => interopDefault(import('../pages/matricular/_courseId/_unitId.vue' /* webpackChunkName: "pages/matricular/_courseId/_unitId" */))
const _3f897e6e = () => interopDefault(import('../pages/matricular/_courseId/_unitId/cadastrar.vue' /* webpackChunkName: "pages/matricular/_courseId/_unitId/cadastrar" */))
const _6b34f759 = () => interopDefault(import('../pages/matricular/_courseId/_unitId/identificacao.vue' /* webpackChunkName: "pages/matricular/_courseId/_unitId/identificacao" */))
const _786d52c8 = () => interopDefault(import('../pages/matricular/_courseId/_unitId/pagamento.vue' /* webpackChunkName: "pages/matricular/_courseId/_unitId/pagamento" */))
const _1e07f62e = () => interopDefault(import('../pages/vestibular/_courseId/_unitId.vue' /* webpackChunkName: "pages/vestibular/_courseId/_unitId" */))
const _7c86f506 = () => interopDefault(import('../pages/vestibular/_courseId/_unitId/cadastrar.vue' /* webpackChunkName: "pages/vestibular/_courseId/_unitId/cadastrar" */))
const _1cde7e64 = () => interopDefault(import('../pages/vestibular/_courseId/_unitId/gerar.vue' /* webpackChunkName: "pages/vestibular/_courseId/_unitId/gerar" */))
const _543b75d4 = () => interopDefault(import('../pages/vestibular/_courseId/_unitId/identificacao.vue' /* webpackChunkName: "pages/vestibular/_courseId/_unitId/identificacao" */))
const _7febb60c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/anotacoes",
    component: _ce4151de,
    children: [{
      path: "",
      component: _0ad88cd4,
      name: "anotacoes"
    }, {
      path: ":enrollmentId",
      component: _1a8f0dc0,
      name: "anotacoes-enrollmentId"
    }]
  }, {
    path: "/avisos",
    component: _215f8acb,
    children: [{
      path: "",
      component: _ff24e5e4,
      name: "avisos"
    }, {
      path: ":alertId",
      component: _67a80868,
      name: "avisos-alertId"
    }]
  }, {
    path: "/biblioteca",
    component: _77feb9d1,
    name: "biblioteca"
  }, {
    path: "/eventos",
    component: _d5ba93ae,
    name: "eventos"
  }, {
    path: "/exerciciosrespondidos",
    component: _733a7cac,
    children: [{
      path: "",
      component: _7a2c5ba2,
      name: "exerciciosrespondidos"
    }, {
      path: ":enrollmentId",
      component: _3008337b,
      name: "exerciciosrespondidos-enrollmentId",
      children: [{
        path: "exercicios/:lessonId?",
        component: _0cf4a6d2,
        name: "exerciciosrespondidos-enrollmentId-exercicios-lessonId"
      }]
    }]
  }, {
    path: "/forum",
    component: _6a67c9c9,
    name: "forum"
  }, {
    path: "/login",
    component: _ec9c6fde,
    name: "login"
  }, {
    path: "/matricula",
    component: _b162a4ae,
    name: "matricula"
  }, {
    path: "/matricular",
    component: _8c58e782,
    name: "matricular"
  }, {
    path: "/matricular-sucesso",
    component: _49b11f8c,
    name: "matricular-sucesso"
  }, {
    path: "/mensalidades",
    component: _33a69ffc,
    children: [{
      path: "",
      component: _f73b8d02,
      name: "mensalidades"
    }, {
      path: ":enrollmentId",
      component: _50e76a6a,
      name: "mensalidades-enrollmentId"
    }]
  }, {
    path: "/meus-cursos",
    component: _b90cde14,
    name: "meus-cursos"
  }, {
    path: "/notas",
    component: _548b58ed,
    children: [{
      path: "",
      component: _9cca8ca0,
      name: "notas"
    }, {
      path: ":enrollmentId",
      component: _3543ec08,
      name: "notas-enrollmentId"
    }]
  }, {
    path: "/pagamento",
    component: _04a0d151,
    name: "pagamento"
  }, {
    path: "/primeiro-acesso",
    component: _132c044c,
    name: "primeiro-acesso"
  }, {
    path: "/prova-virtual",
    component: _0c676e81,
    name: "prova-virtual"
  }, {
    path: "/provas",
    component: _16b155e3,
    children: [{
      path: "",
      component: _08deb3b4,
      name: "provas"
    }, {
      path: ":enrollmentId",
      component: _4f99bf1c,
      children: [{
        path: "",
        component: _0fae0975,
        name: "provas-enrollmentId"
      }, {
        path: ":schedulingId",
        component: _8e96c95e,
        name: "provas-enrollmentId-schedulingId"
      }]
    }]
  }, {
    path: "/saladeaula",
    component: _225ef8f8,
    name: "saladeaula"
  }, {
    path: "/vestibular",
    component: _cfd7e788,
    name: "vestibular"
  }, {
    path: "/aluno/perfil",
    component: _2401ad78,
    name: "aluno-perfil",
    children: [{
      path: "contato",
      component: _9a2ba9da,
      name: "aluno-perfil-contato"
    }, {
      path: "informacoes-pessoais",
      component: _7f38fb33,
      name: "aluno-perfil-informacoes-pessoais"
    }, {
      path: "redefinir-senha",
      component: _0f1a5c31,
      name: "aluno-perfil-redefinir-senha"
    }]
  }, {
    path: "/biblioteca/curso",
    component: _5ceec6ca,
    name: "biblioteca-curso",
    children: [{
      path: ":cursoId?/:page?",
      component: _47e7695b,
      name: "biblioteca-curso-cursoId-page"
    }]
  }, {
    path: "/biblioteca/labs",
    component: _5134f91a,
    name: "biblioteca-labs"
  }, {
    path: "/matricula/pre-cadastro",
    component: _120cbe9f,
    name: "matricula-pre-cadastro"
  }, {
    path: "/matricula/pre-cadastro/confirmacao",
    component: _4b96e99d,
    name: "matricula-pre-cadastro-confirmacao"
  }, {
    path: "/matricula/pre-cadastro/email",
    component: _7edd272e,
    name: "matricula-pre-cadastro-email"
  }, {
    path: "/matricula/pre-cadastro/finalizar-cadastro",
    component: _39df8051,
    name: "matricula-pre-cadastro-finalizar-cadastro"
  }, {
    path: "/matricula/pre-cadastro/login",
    component: _48973c94,
    name: "matricula-pre-cadastro-login"
  }, {
    path: "/biblioteca/digital/:page?",
    component: _c254c794,
    name: "biblioteca-digital-page"
  }, {
    path: "/biblioteca/labs/:labId",
    component: _035db81e,
    name: "biblioteca-labs-labId"
  }, {
    path: "/biblioteca/publicacao/:tipo?/:id?",
    component: _58f20539,
    name: "biblioteca-publicacao-tipo-id"
  }, {
    path: "/eventos/:eventId",
    component: _5e1a08e2,
    name: "eventos-eventId"
  }, {
    path: "/matricula/:courseId",
    component: _c82ff868,
    children: [{
      path: "",
      component: _0844fd62,
      name: "matricula-courseId"
    }, {
      path: ":teachingUnit",
      component: _0bd8bccd,
      name: "matricula-courseId-teachingUnit",
      children: [{
        path: "informacoes",
        component: _13ad8f20,
        name: "matricula-courseId-teachingUnit-informacoes"
      }]
    }]
  }, {
    path: "/matricular/:courseId",
    component: _4a659c65,
    name: "matricular-courseId"
  }, {
    path: "/pagamento/:enrollmentId",
    component: _3766f09d,
    name: "pagamento-enrollmentId"
  }, {
    path: "/recuperar-senha/:message?",
    component: _78d77964,
    name: "recuperar-senha-message"
  }, {
    path: "/resetar-senha/:token?",
    component: _1cdb5269,
    name: "resetar-senha-token"
  }, {
    path: "/saladeaula/:enrollmentId",
    component: _469afd78,
    name: "saladeaula-enrollmentId",
    children: [{
      path: "aula",
      component: _4c08d447,
      name: "saladeaula-enrollmentId-aula"
    }, {
      path: "aula/:lessonId",
      component: _5f332272,
      children: [{
        path: "",
        component: _e7bef7ec,
        name: "saladeaula-enrollmentId-aula-lessonId"
      }, {
        path: "exerciciosrespondidos",
        component: _6750bbbc,
        name: "saladeaula-enrollmentId-aula-lessonId-exerciciosrespondidos"
      }, {
        path: ":file",
        component: _d45fc5da,
        name: "saladeaula-enrollmentId-aula-lessonId-file"
      }]
    }]
  }, {
    path: "/vestibular/:courseId",
    component: _194ff7bc,
    name: "vestibular-courseId"
  }, {
    path: "/prova-virtual/:examRequestId?/finished",
    component: _4fb5b238,
    name: "prova-virtual-examRequestId-finished"
  }, {
    path: "/prova-virtual/:examRequestId?/info",
    component: _f5446bc0,
    name: "prova-virtual-examRequestId-info"
  }, {
    path: "/prova-virtual/:examRequestId?/start",
    component: _60a7f760,
    name: "prova-virtual-examRequestId-start"
  }, {
    path: "/matricular/:courseId/:unitId",
    component: _2fdeec9e,
    name: "matricular-courseId-unitId",
    children: [{
      path: "cadastrar",
      component: _3f897e6e,
      name: "matricular-courseId-unitId-cadastrar"
    }, {
      path: "identificacao",
      component: _6b34f759,
      name: "matricular-courseId-unitId-identificacao"
    }, {
      path: "pagamento",
      component: _786d52c8,
      name: "matricular-courseId-unitId-pagamento"
    }]
  }, {
    path: "/vestibular/:courseId/:unitId",
    component: _1e07f62e,
    name: "vestibular-courseId-unitId",
    children: [{
      path: "cadastrar",
      component: _7c86f506,
      name: "vestibular-courseId-unitId-cadastrar"
    }, {
      path: "gerar",
      component: _1cde7e64,
      name: "vestibular-courseId-unitId-gerar"
    }, {
      path: "identificacao",
      component: _543b75d4,
      name: "vestibular-courseId-unitId-identificacao"
    }]
  }, {
    path: "/",
    component: _7febb60c,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
