import Driver from 'driver.js'
import TutorialService from '@/services/tutorial'
import { Utils } from '@/services/utils'

export default class BaseTutorial {
  constructor(store, tutorialName) {
    this.vueStore = store
    this.name = tutorialName

    const configs = this.vueStore.getters['config/getAllDefaultOptions']
    this.genero = configs.genero === 'F' ? 'a' : 'o'
    this.nomeUnidade = configs.nome

    this.SIDEBAR_ELEMENT = document.querySelector('#student-area')

    this._tutorial = new Driver({
      animate: false,
      allowClose: false,
      doneBtnText: 'Pronto',        // Text on the final button
      closeBtnText: 'Fechar',       // Text on the close button for this step
      stageBackground: '#ffffff',   // Background color for the staged behind highlighted element
      nextBtnText: 'Próximo',       // Next button text for this step
      prevBtnText: 'Anterior',
      onHighlighted: el => {

      },
      onReset: el => {
        setTimeout(() => {
          this._afterEnd()
        }, 100)
      }
    })

    this.defineTutorial()
  }
  openSidebar() {
    this.SIDEBAR_ELEMENT.classList.add('nav-open')
  }
  closeSidebar() {
    this.SIDEBAR_ELEMENT.classList.remove('nav-open')
  }
  start() {
    Utils.scrollToTop(true)
    this._beforeStart()

    let timeout = 0
    if (this.vueStore.state.app.sidebar.open) timeout = 500
    setTimeout(() => {this._tutorial.start()}, timeout)
  }
  reset() {
    this._tutorial.reset(true)
  }
  defineTutorial() {
    throw new Error('implent defineTutorial method in child class!')
  }
  _beforeStart(){
    document.querySelector('#__nuxt').style.pointerEvents = "none"

    const chatButton = document.querySelector('jdiv.globalClass_ET')
    if (chatButton !== null) {
      chatButton.classList.add('d-none')
    }
  }
  _afterEnd(){
    document.querySelector('#__nuxt').style.pointerEvents = "initial"

    const chatButton = document.querySelector('jdiv.globalClass_ET')
    if (chatButton !== null) {
      chatButton.classList.remove('d-none')
    }
    TutorialService.finish(this.name)
    this.SIDEBAR_ELEMENT.classList.remove('nav-open')
    this.vueStore.commit('app/finishTutorial', this.name)
    return
  }
}
