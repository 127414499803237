// Middleware que busca o token do local storage caso o mesmo esteja salvo neste

export default async ({store,context}) => {
  let persistedStore = localStorage.getItem('nt-platneb-auth-token')
  let persistedEnrollStore = JSON.parse(localStorage.getItem('nt-enroll'))
  store.commit('enroll/loadFromLocalStorage', persistedEnrollStore)
  if (persistedStore !== null) {
    await store.commit('app/authenticate', persistedStore)
    await store.dispatch('app/getUser')
  } else {
    store.commit('app/unauthenticate')
  }
}
