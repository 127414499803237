const state = () => {
  return {
    deviceData: {}
  }
}

const getters = {
  getDeviceData(state, getters, rootState) {
    return state.deviceData;
  },
  getPlatform(state, getters, rootState) {
    return state.deviceData && state.deviceData.platform ? state.deviceData.platform : 'browser';
  }
}

const mutations = {
  setDeviceData(state) {
    state.deviceData = device;
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
