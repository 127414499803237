import { axios } from '@/plugins/axios'

export class Classroom {
  static getMyCourses () {
    return axios.get('/classroom/my-courses')
  }

  static getCourseSyllabus (enrollmentId) {
    return axios.get('/classroom/syllabus?enrollment_id=' + enrollmentId)
  }

  static getLessonContents (enrollmentId, lessonId) {
    return axios.get('/classroom/lesson?enrollment_id=' + enrollmentId + '&lesson=' + lessonId)
  }

  static getSagahAuthenticate (enrollmentId, lessonId, contentId) {
    return axios.get('/classroom/sagah-authenticate', { params: {
        enrollment_id: enrollmentId,
        lesson_id: lessonId,
        content_id: contentId
    }})
  }

  static getForumWithTopicAndAnswers (enrollmentId, lessonId) {
    return axios.get('/forum/topic/answers?enrollment_id=' + enrollmentId + '&lesson=' + lessonId)
  }

  static getLessonExercises (enrollmentId, lessonId) {
    return axios.get('/classroom/exercise?enrollment_id=' + enrollmentId + '&lesson=' + lessonId)
  }

  static saveExerciseAnswer (enrollmentId, exerciseId, studentAsnwer, file) {

    let data = new FormData();
    data.append('enrollment_id', enrollmentId)
    data.append('exercise', exerciseId)
    data.append('answer', studentAsnwer)
    data.append('file', file)
    return axios.post('/classroom/exercise/answer', data, {headers: {'content-type': 'multipart/form-data'}})
  }

  static saveTopicAnswer (data) {
    return axios.post('/forum/topic/new/answer', {
      topicoId: data.topicoId,
      mensagem: data.mensagem,
      enrollmentId: data.enrollmentId,
      resposta_referenciada_id: data.resposta_referenciada_id
    })
  }

  static saveRateLesson (enrollmentId, lessonId, rate) {
    return axios.post('/classroom/lesson/rate', {
      rate: rate,
      lesson_id: lessonId,
      enrollment_id: enrollmentId
    })
  }

  static saveLessonNotes (enrollmentId, lessonId, textContent) {
    return axios.post('/classroom/lesson/notes', {
      enrollment_id: enrollmentId,
      lesson: lessonId,
      textContent: textContent
    })
  }

  static getLessonFile (enrollmentId, lessonId, fileName) {
    return axios.get('/classroom/lesson/file', {
      params: {
        enrollment_id: enrollmentId,
        lesson_id: lessonId,
        file_name: fileName
      }
      // responseType: 'blob'
    })
  }
}
