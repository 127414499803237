<template>
  <div>
    <video id="nt-video-player"/>
  </div>
</template>

<script>
import Plyr from 'plyr'
import 'plyr/src/sass/plyr.scss'

export default {
  name: 'NtPlayer',
  props: {
    type: {type: String, default: null},
    path: {type: String, default: ''},
    title: {type: String, default: ''}
  },
  data () {
    return {
      player: null,
      timeoutVideoMonitor: null
    }
  },
  mounted () {
    let self = this;
    this.$store.commit('app/startLoading')
    this.player = new Plyr('#nt-video-player', {
      controls: ['play-large', 'play', 'progress', 'current-time', 'duration', 'mute', 'settings', 'airplay', 'fullscreen'],
      invertTime: false,
      autoplay: false
    })

    let source = {
      type: 'video',
      sources: [
        {
          src: this.path,
        }
      ],
      poster: '/video_poster_black.jpg',
    }

    this.player.on('enterfullscreen', event => {
        AndroidFullScreen.isImmersiveModeSupported(()=> {
          AndroidFullScreen.immersiveMode();
        }, ()=>{});
    });

    this.player.on('exitfullscreen', event => {
        AndroidFullScreen.isImmersiveModeSupported(()=> {
          AndroidFullScreen.showSystemUI();
        }, ()=>{});
    });

    if (this.type === 'vimeo' || this.type === 'youtube') {
      source.sources[0].provider = this.type.toLowerCase()
    } else {
      source.sources[0].type = 'video/' + this.type
    }

    this.player.source = source

    if (typeof this.endVideo === 'function') {
      this.player.on('ended', event => {
        if (this.player.fullscreen.active) {
          this.player.fullscreen.exit()
        }
        this.endVideo()
      })
    }

    this.player.on('ready', () => {
      this.player.play()
      setTimeout(() => {this.$store.commit('app/endLoading')}, 500)
    })
  },
  beforeDestroy () {
    if (this.timeoutVideoMonitor) {
      clearInterval(this.timeoutVideoMonitor)
    }
    this.player.destroy()
  }
}
</script>
