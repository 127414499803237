<template>
  <div
    id="student-area"
    ref="wrapper"
    :class="[{'nav-open': sidebarOpened}, theme]">
    <div class="wrapper">
      <aside>
        <nt-sidebar :sidebar-links="menu_items">
          <nt-navbar-mobile
            slot="content"
            @tutorial="playTutorial"
          />
        </nt-sidebar>
      </aside>
      <div
        class="main--panel"
        @click="closeSidebar()">
        <div>
          <nt-top-navbar
            @logout="logout"
            @tutorial="playTutorial"
          />
          <div class="content py-4">
            <div class="px-3">
              <nt-content/>
            </div>
          </div>
        </div>
      </div>
      <nt-footer/>
    </div>
    <div
      v-if="defaultOptions.telefone_whatsapp"
      class="btn-whatsapp">
      <a
        :href="`https://wa.me/55${defaultOptions.telefone_whatsapp}`"
        class="link"
        target="_blank">
        <i class="fa fa-whatsapp"/>
      </a>
      <div class="description">
        Precisa de ajuda?<br>Clique aqui e entre em contato pelo whatsapp.
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/layout/Footer.vue'
import Content from '@/components/layout/Content.vue'
import { mapGetters } from 'vuex'
import NavBarMobile from '@/components/layout/NavBarMobile.vue'
import SideBar from '@/components/layout/SideBar.vue'
import TopNavBar from '@/components/layout/TopNavBar.vue'
import topBarColors from '@/components/utils/themeColors'
import mainTutorial from '@/common/tutorials/main'

export default {
  middleware: ['authenticated', 'route-manager'],
  components: {
    'nt-footer': Footer,
    'nt-top-navbar': TopNavBar,
    'nt-content': Content,
    'nt-sidebar': SideBar,
    'nt-navbar-mobile': NavBarMobile
  },
  head () {
    return {
      title: 'Platneb EaD - ' + this.pageTitle,
      meta: [
        { name: 'theme-color', content: topBarColors[this.theme]['frameTopColor'] },
        { name: 'msapplication-navbutton-color', content: topBarColors[this.theme]['frameTopColor'] },
        { name: 'apple-mobile-web-app-status-bar-style', content: topBarColors[this.theme]['frameTopColor'] }
      ]
    }
  },
  data: function () {
    return {
      drawer: false,
      fixed: true,
      systemModules: [
        { icon: 'fa fa-graduation-cap', id: 'sidebar-cursos', title: 'Meus Cursos', to: { name: 'meus-cursos' } },
        { icon: 'fa fa-calendar-o', id: 'sidebar-cursos', title: 'Eventos', to: { name: 'eventos' } },
        { icon: 'fa fa-bell', id: 'sidebar-avisos', title: 'Avisos', to: { name: 'avisos' } },
        { icon: 'fa fa-credit-card', id: 'sidebar-mensalidades', title: 'Mensalidades', to: { name: 'mensalidades' } },
        { icon: 'fa fa-bookmark', id: 'sidebar-provas', title: 'Provas', to: { name: 'provas' } },
        { icon: 'fa fa-clipboard', id: 'sidebar-notas', title: 'Quadro de Notas', to: { name: 'notas' } },
        { icon: 'fa fa-list-ul', id: 'sidebar-anotacoes', title: 'Anotações', to: { name: 'anotacoes' } },
        { icon: 'fa fa-book', id: 'sidebar-exercicios', title: 'Exercícios', to: { name: 'exerciciosrespondidos' } },
        { icon: 'fa fa-university', id: 'sidebar-bibliotecas', title: 'Bibliotecas', to: { name: 'biblioteca' } }
      ],
      right: false,
      rightDrawer: false,
      tutorial: null
    }
  },
  computed: {
    sidebarOpened () {
      return this.$store.state.app.sidebar.open
    },
    menu_items() {
      return this.systemModules.filter(item => {
        return this.blockedModules ? this.blockedModules.indexOf(item.to.name) === -1 : false
      })
    },
    ...mapGetters({
      theme: 'app/theme',
      pageTitle: 'app/pageTitle',
      blockedModules: 'config/blockedModules',
      defaultOptions: 'config/getAllDefaultOptions'
    })
  },
  mounted () {
    this.tutorial = new mainTutorial(this.$store, 'main')
  },
  methods: {
    logout: function () {
      this.$store.dispatch('app/logout').then((response) => {
        location.href = '/login'
      })
    },
    detectSwipe (element, f) {
      let detect = {
        startX: 0,
        startY: 0,
        endX: 0,
        endY: 0,
        minX: 30, // min X swipe for horizontal swipe
        maxX: 30, // max X difference for vertical swipe
        minY: 50, // min Y swipe for vertial swipe
        maxY: 60 // max Y difference for horizontal swipe
      }
      let direction = null

      element.addEventListener('touchstart', function (event) {
        var touch = event.touches[0]
        detect.startX = touch.screenX
        detect.startY = touch.screenY
      })

      element.addEventListener('touchmove', function (event) {
        event.preventDefault()
        var touch = event.touches[0]
        detect.endX = touch.screenX
        detect.endY = touch.screenY
      })

      element.addEventListener('touchend', function (event) {
        if (
          // Horizontal move.
          (Math.abs(detect.endX - detect.startX) > detect.minX) && (Math.abs(detect.endY - detect.startY) < detect.maxY)
        ) {
          direction = (detect.endX > detect.startX) ? 'right' : 'left'
        } else if (
          // Vertical move.
          (Math.abs(detect.endY - detect.startY) > detect.minY) && (Math.abs(detect.endX - detect.startX) < detect.maxX)
        ) {
          direction = (detect.endY > detect.startY) ? 'down' : 'up'
        }

        if ((direction !== null) && (typeof f === 'function')) {
          f(element, direction)
        }
      })
    },
    playTutorial() {
      this.tutorial.start()
    },
    closeSidebar () {
      this.$store.commit('app/toggleSidebar', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  border-radius: 50%;
  z-index: 10;

  i {
    color: #fff;
    font-size: 2.8rem;
    padding: 10px 13px;
  }

  .description {
    position: absolute;
    background-color: #fff;
    display: none;
    top: -21px;
    right: 69px;
    width: 200px;
    text-align: center;
    padding: 7px;
    border-radius: 5px;
  }

  a:hover .description {
    display: block;
  }
}

.btn-whatsapp:hover .description {
  display: block;
}
</style>
