const middleware = {}

middleware['authenticated-enroll'] = require('../middleware/authenticated-enroll.js')
middleware['authenticated-enroll'] = middleware['authenticated-enroll'].default || middleware['authenticated-enroll']

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['configured'] = require('../middleware/configured.js')
middleware['configured'] = middleware['configured'].default || middleware['configured']

middleware['route-manager'] = require('../middleware/route-manager.js')
middleware['route-manager'] = middleware['route-manager'].default || middleware['route-manager']

export default middleware
