<template>
  <b-card
    v-if="hasChildren"
    :id="'card' + accordion + '-' + branch.id"
    no-body
    class="p-0">
    <b-card-header
      :class="['p-0', {'active-lesson': branch.active}]"
      role="tab">
      <b-btn
        v-b-toggle="accordion + '-' + branch.id"
        :variant="branch.blocked ? 'sidebar-disabled' : 'secondary'"
        class="d-flex"
        block>
        <span>
          <i
            v-if="branch.blocked"
            class="fa fa-lock"/> {{ branch.text }}</span>
      </b-btn>
    </b-card-header>
    <b-collapse
      :ref="branch.active ? 'active-' + accordion + '-' + branch.id : null"
      :id="accordion + '-' + branch.id"
      :accordion="accordion"
      :visible="branch.active">
      <template>
        <list-item
          v-for="(node, index) in branch.nodes"
          :key="index"
          :accordion="accordion + '-' + branch.id"
          :branch="node"
          :active="active"/>
      </template>
    </b-collapse>
  </b-card>
  <nuxt-link
    v-else-if="!branch.blocked"
    :to="{name: 'saladeaula-enrollmentId-aula-lessonId', params: { enrollmentId: this.$route.params.enrollmentId, lessonId: branch.aula_id }}"
    :class="['clickable', {'active-lesson': branch.active}]"
    replace
    tag="b-card"
    text-variant="white">
    <span>
      <i
        v-if="branch.active"
        class="fa fa-play"/>
      {{ branch.text }}
    </span>
  </nuxt-link>
  <b-card
    v-else
    text-variant="white"
    class="link-disabled">
    <i class="fa fa-lock"/>
    {{ branch.text }}
  </b-card>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    branch: {type: Object, default: null},
    active: {type: Object, default: null},
    accordion: {type: String, default: ''}
  },
  computed: {
    hasChildren () {
      return this.branch.nodes ? true : false
    }
  },
  watch: {
    branch:{
      handler(val, oldVal) {
        this.$nextTick(() => {
          Object.values(this.$refs).forEach((ref) => {
            if (ref && !ref.$data.show) {
              ref.toggle()
            }
          })
          let element = document.querySelector('#' + this.accordion)
          if (element)  {
            setTimeout(() => element.scrollIntoView({
              behavior: 'auto',
              block: 'center',
              inline: 'center'
            }), 500)
          }
        })
      },
      deep: true
    }
  }
}
</script>

<style>
#classroom .sidebar .link-disabled {
  cursor: not-allowed;
}
</style>
