import baseTutorial from '@/common/classes/baseTutorial'
import { Utils } from '@/services/utils'

const SCREEN_WIDTH = window.innerWidth

const toggleSidebar = SCREEN_WIDTH < 991

export default class MainTutorial extends baseTutorial {
  constructor(store, name) {
    super(store, name)
  }
  start() {
    this.openSidebar()
    Utils.scrollSidebarToTop(true)
    setTimeout(() => super.start(), 500)
  }
  defineTutorial() {
    this._tutorial.defineSteps([
      {
        element: '.logo-img',
        popover: {
          title: 'Bem vindo!',
          description: `
            Seja bem vindo a plataforma de ensino EAD d` + this.genero + ` ` + this.nomeUnidade + `.
            Vamos conhecer melhor esta ferramenta que vai lhe auxiliar nos seus estudos!`,
          position: toggleSidebar ? 'bottom-right' : 'right'
        },
        onNext: el => {
          if (toggleSidebar) {
            this._tutorial.preventMove()
            this.closeSidebar()
            setTimeout(() => {
              this._tutorial.currentStep === 0 ? this._tutorial.moveNext() : null
            }, 500)
          }
        }
      },
      {
        element: '.navbar-brand',
        popover: {
          title: 'Está com duvídas?',
          description: `Basta clickar no ícone <i class="fa fa-question-circle-o"></i> para ficar sabendo mais sobre a página que você está agora.`,
          position: toggleSidebar ? 'bottom' : 'right'
        },
        onNext: el => {
          if (toggleSidebar) {
            this._tutorial.preventMove()
            this.openSidebar()
            setTimeout(() => {
              this._tutorial.currentStep === 1 ? this._tutorial.moveNext() : null
            }, 500)
          }
        },
        onPrevious: el => {
          if (toggleSidebar) {
            this._tutorial.preventMove()
            this.openSidebar()
            setTimeout(() => {
              this._tutorial.movePrevious()
            }, 500)
          }
        }
      },
      {
        element: '#sidebar',
        popover: {
          title: 'Ainda tem mais!',
          description: `Essas são as outras áreas da plataforma, vamos ver o que você encontra em cada uma delas?`,
          position: toggleSidebar ? 'top-right' : 'right'
        },
        onPrevious: el => {
          if (toggleSidebar) {
            this._tutorial.preventMove()
            this.closeSidebar()
            setTimeout(() => {
              this._tutorial.movePrevious()
            }, 500)
          }
        }
      },
      {
        element: '#sidebar #sidebar-cursos',
        popover: {
          title: 'Meus cursos',
          description: `Aqui você tem acesso aos detalhes de todos os cursos que você está matriculado.`,
          position: toggleSidebar ? 'bottom-right' : 'right'
        }
      },
      {
        element: '#sidebar #sidebar-avisos',
        popover: {
          title: 'Avisos',
          description: `Aqui é possível ver todos os avisos que você já recebeu e lê-los.`,
          position: toggleSidebar ? 'bottom-right' : 'right'
        }
      },
      {
        element: '#sidebar #sidebar-mensalidades',
        popover: {
          title: 'Mensalidades',
          description: `Aqui você pode ver o status financeiro dos seus cursos. Acompanhar pagamentos, emitir boletos, realizar novos pagamentos, entre outros.`,
          position: toggleSidebar ? 'bottom-right' : 'right'
        }
      },
      {
        element: '#sidebar #sidebar-provas',
        popover: {
          title: 'Provas',
          description: `Aqui você pode ver as provas que já fez e as provas que irá fazer em breve. Além de poder se inscrever em algum agendamento já cadastrado.`,
          position: toggleSidebar ? 'top-right' : 'right'
        }
      },
      {
        element: '#sidebar #sidebar-notas',
        popover: {
          title: 'Quadro de notas',
          description: `Aqui você tem acesso as suas notas em tempo real, separadas por tópicos/disciplinas.`,
          position: toggleSidebar ? 'top-right' : 'right'
        }
      },
      {
        element: '#sidebar #sidebar-anotacoes',
        popover: {
          title: 'Anotações',
          description: `Aqui você pode acessar todas as anotações que fez na sala de aula, pesquisando pelo nome da aula.`,
          position: toggleSidebar ? 'top-right' : 'right'
        }
      },
      {
        element: '#sidebar #sidebar-exercicios',
        popover: {
          title: 'Exercícios',
          description: `Aqui você acessa todos os exercícios que respondeu na sala de aula, separados por aula e com o gabarito.`,
          position: toggleSidebar ? 'top-right' : 'right'
        }
      },
      {
        element: '#sidebar #sidebar-bibliotecas',
        popover: {
          title: 'Bibliotecas',
          description: `Aqui você pode acessar a biblioteca com o conteúdo do seu curso ou diversas outras bibliotecas digitais.`,
          position: toggleSidebar ? 'top-right' : 'right'
        }
      },
      {
        element: toggleSidebar ? '#mobile-profile-panel' : 'ul.navbar-nav li:last-child .btn-group.b-dropdown',
        popover: {
          title: 'Perfil e Sair',
          description: `Aqui você consegue rever esse tutorial, ver o seu perfil e sair da plataforma.`,
          position: 'bottom-right'
        }
      },
    ])
  }
}
