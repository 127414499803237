<template>
  <div
    id="mobile-profile-panel"
    class="nt-invisible-lg"
  >
    <ul class="nav flex-column">
      <li class="text-center">
        <img
          :src="avatar"
          class="rounded-circle"
          style="width: auto; height: 80px;"
          alt="">
      </li>
      <li class="text-center pt-3">
        <p>
          Olá, <span
            v-if="firstName"
            class="text-capitalize">{{ firstName }}!</span>
        </p>
      </li>

      <li class="d-flex justify-content-between px-3">
        <b-button
          type="button"
          variant="info"
          @click="$emit('tutorial')">
          <i class="fa fa-question-circle-o mx-3"/>
        </b-button>
        <b-dropdown
          text="Dropdown Button"
          variant="info">
          <template slot="button-content">
            <i class="fa fa-paint-brush mx-3"/>
          </template>
          <b-dropdown-item @click="changeTheme('osiris')">
            <div class="d-flex justify-content-between align-items-center">
              Light<span class="ml-2 preview-colors-osiris"/>
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click="changeTheme('seth')">
            <div class="d-flex justify-content-between align-items-center">
              Dark<span class="ml-2 preview-colors-seth"/>
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click="changeTheme('horus')">
            <div class="d-flex justify-content-between align-items-center">
              Gray<span class="ml-2 preview-colors-horus"/>
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <b-button
          type="button"
          variant="danger"
          @click="logout">
          <i class="fa fa-power-off mx-3"/>
        </b-button>
      </li>

      <nuxt-link
        :to="{ name: 'aluno-perfil-informacoes-pessoais' }"
        class="nav-link nt-item-link"
        tag="li">
        <div class="d-flex flex-row align-items-center">
          <i class="fa fa-user color-secondary"/>
          <p class="w-75">Meu Perfil</p>
        </div>
      </nuxt-link>
    </ul>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      firstName: function () {
        if (this.name) {
          return this.name.split(' ')[0].toLowerCase()
        }
      },
      ...mapGetters({
        activeTheme: 'app/activeTheme',
        name: 'app/name',
        avatar: 'app/avatar'
      })
    },
    methods: {
      logout: function () {
        this.$store.dispatch('app/logout').then((response) => {
          location.href = '/login'
        })
      },
      changeTheme: function (themeName) {
        this.$store.commit('app/changeTheme', themeName)
      },
      previewTheme: function (themeName) {
        this.$store.commit('app/previewTheme', themeName)
      }
    }
  }
</script>
