import {Jwt} from '@/services/jwt'
import {User} from '@/services/user'
import Driver from 'driver.js'

// Estado geral da aplicação
const defaultThemeName = 'osiris'
const defaultUserState = {
  name: '',
  gender: '',
  email: '',
  phone: '',
  dateOfBirth: '',
  cpf: ''
}

const state = () => {
  return {
    nextLogin: '/',
    sidebar: {
      open: false
    },
    theme: {
      name: defaultThemeName
    },
    activeTheme: {
      name: defaultThemeName
    },
    auth: {
      token: null,
      check: false,
      user: {
        student: defaultUserState,
        tutorial: {}
      },
      isRefreshing: false
    },
    page: {
      title: 'Ambiente do Aluno',
      tutorial: null
    },
    loading: false,
    tutorials: {}
  }
}

const getters = {
  pageTitle: function (state) {
    return state.page.title
  },
  currentTutorial: function(state) {
    return state.page.tutorial
  },
  activeTheme: function (state) {
    return state.activeTheme.name
  },
  theme: function (state) {
    return state.theme.name
  },
  loading (state) {
    return state.loading
  },
  name (state) {
    if(state.auth.user.student) {
      return state.auth.user.student.nome
    }
  },
  avatar (state) {
    if(state.auth.user) {
      return state.auth.user.avatar
    }
  },
  completedTutorials (state) {
    return state.tutorials
  }
}

const mutations = {
  update: function (state, valor) {
    state.view = valor
  },
  setUser (state, user) {
    state.auth.user = { ...user }
  },
  // Autentica um usuário
  authenticate (state, token) {
    state.auth.token = token
    state.auth.check = true
    localStorage.setItem('nt-platneb-auth-token', token)
  },
  // Desautentica um usuário
  unauthenticate (state) {
    state.auth.check = false
    state.auth.user = {...defaultUserState}
    state.auth.token = null

    localStorage.removeItem('nt-platneb-auth-token')
  },
  // Mostra o status de loading
  startLoading (state) {
    state.loading = true
  },
  // Esconde o status de loading
  endLoading (state) {
    state.loading = false
  },
  toggleSidebar (state, value) {
    if (value === undefined) {
      state.sidebar.open = !state.sidebar.open
    } else {
      state.sidebar.open = value
    }
  },
  changeTheme (state, value) {
    if(state.auth.check) {
      User.storeOption('theme', value);
    }
    state.theme.name = value
    state.activeTheme.name = value
  },
  previewTheme (state, value) {
    state.theme.name = value
  },
  setPageTitle (state, value) {
    state.page.title = value
  },
  setPageTutorial (state, value) {
    state.page.tutorial = value
  },
  startTokenRefresh (state) {
    state.auth.isRefreshing = true
  },
  finishTokenRefresh (state) {
    state.auth.isRefreshing = false
  },
  setCompletedTutorials (state, tutorials) {
    state.tutorials = tutorials
  },
  finishTutorial(state, name) {
    if (!state.tutorials)
      state.tutorials = {}
    state.tutorials[name] = { finished: true}
  },
  changeNextLogin(state, url) {
    state.nextLogin = url
  }
}

const actions = {
  // Realiza o login de um usuário
  login (context, {login, password, platform}) {
    return Jwt.accessToken(login, password, platform).then((response) => {
      context.commit('authenticate', response.data.access_token)
      context.dispatch('getUser')

      return response
    })
  },
  // Desloga o usuário atual
  logout (context) {
    return Jwt.unauthenticate().finally(() => {
      context.commit('unauthenticate')
    })
  },
  // Recupera o objeto referente a um usuário do backend
  getUser (context) {
    User.getWithStudent().then((response) => {
      context.commit('setUser', response.data)
      context.commit('setCompletedTutorials', response.data.tutorial)
      if(response.data.options && response.data.options.theme) {
        context.commit('changeTheme', response.data.options.theme)
      }
    })
  },
  // Recupera o objeto referente a um usuário do backend
  getUserByToken (context, { token }) {
    return User.getByToken(token)
  },
  // Recupera o objeto referente a um usuário do backend
  getUserWithStudent (context) {
    return User.getWithStudent()
  },
  // Envia um email de redefinição de senha caso o login
  // exista
  resetPassword (context, { login }) {
    return Jwt.resetPassword(login)
  },
  // Salva uma nova senha para um usuário
  saveNewPassword (context, params) {
    return Jwt.saveNewPassword(params.token, params.password, params.passwordConfirmation)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
