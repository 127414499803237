/* -------------------------------- +
 |  Busca o token do local storage  |
 + -------------------------------- */

/* eslint-disable */
import Axios from 'axios'

const retryRequest = (request, token) => {
  request.headers.Authorization = 'Bearer ' + token
  request.url = request.url.replace(request.baseURL, '')
  return Axios(request)
}

let axios = null
function readBlob(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = () => {
      reject(new DOMException('Erro.'))
    }
    reader.onload = e => {
      resolve(e.srcElement.result)
    }

    reader.readAsText(blob)
  })
}

export default ({ store, app, redirect }) => {
  app.$axios.pendingRequests = 0
  // Insere o header de autenticação para as requisições
  app.$axios.onRequest(function(config) {
    app.$axios.pendingRequests++
    if (!(config.showLoading === false)) {
      store.commit('app/startLoading')
    }
    if (!config.noAuth && store.state.app.auth.token) {
      config.headers.Authorization = 'Bearer ' + store.state.app.auth.token
    }
  })

  app.$axios.onResponse(function(config) {
    store.commit('app/endLoading')
    app.$axios.pendingRequests--
  })

  /*
  * Caso a requisição retorne 401 mas o store indique que o usuário está autenticado,
  * sendo assim recupera um novo token com a função refresh token.
  */
  app.$axios.onResponseError(async error => {
    store.commit('app/endLoading')
    if (!Axios.isCancel(error)) {
      const originalRequest = error.config

      // Caso a requisição interceptada seja de refresh token,
      // redireciona para a tela de login
      if (originalRequest.url === process.env.baseUrl + '/auth/refresh') {
        app.$toast.error(error.response.statusText)
        store.commit('auth/unauthenticate')

        return redirect('/')
      }

      // Tratando resposta blob quando é necessário fazer um refresh de token
      if (
        error.response && error.response.data instanceof Blob &&
        error.response.headers['content-type'] === 'application/json' &&
        error.response.status === 401
      ) {
        await readBlob(error.response.data).then(data => {
          Object.assign(
            error.response.data,
            JSON.parse(data.replace(/[\\]/g, '\\'))
          )
        })
      }

      if (error.response.status === 401)  {
        if (error.response.data.exception === process.env.tokenExpiredException && store.state.app.auth.check) {
          if (error.response.data.access_token) {
            store.commit('app/authenticate', error.response.data.access_token)
            app.$axios.pendingRequests--;
            return retryRequest(originalRequest, store.state.app.auth.token)
          } else {
            store.commit('app/unauthenticate')
            redirect('/login')
            throw new Error()
          }
        } else if (error.response.data.exception === process.env.tokenBlacklistedException) {
          if (originalRequest.headers.Authorization.slice(7) === store.state.app.auth.token) {
            store.commit('app/unauthenticate')
            redirect('/login')
          } else {
            app.$axios.pendingRequests--;
            return retryRequest(originalRequest, store.state.app.auth.token)
          }
        }
      }
    }
    app.$axios.pendingRequests--;
    throw error
  })

  axios = app.$axios
}

export { axios }
