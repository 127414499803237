<template>
  <div :class="theme">
    <nuxt/>
    <div
      v-if="defaultOptions.telefone_whatsapp"
      class="btn-whatsapp">
      <a
        :href="`https://wa.me/55${defaultOptions.telefone_whatsapp}`"
        class="link"
        target="_blank">
        <i class="fa fa-whatsapp"/>
      </a>
      <div class="description">
        Precisa de ajuda?<br>Entre em contato pelo whatsapp.
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        theme: 'app/theme',
        defaultOptions: 'config/getAllDefaultOptions'
      })
    }
  }
</script>
<style lang="scss" scoped>
.btn-whatsapp {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  border-radius: 50%;
  z-index: 10;

  i {
    color: #fff;
    font-size: 2.8rem;
    padding: 10px 13px;
  }

  .description {
    position: absolute;
    background-color: #fff;
    display: none;
    top: -21px;
    right: 69px;
    width: 200px;
    text-align: center;
    padding: 7px;
    border-radius: 5px;
  }

  a:hover .description {
    display: block;
  }
}

.btn-whatsapp:hover .description {
  display: block;
}
</style>
