/* ------------------------------------------- +
 |  Busca as configs da aplicação no back-end  |
 + ------------------------------------------- */
export default async function ({ store }) {
  if (Object.keys(store.state.config.default_options).length === 0) {
    await store.dispatch('config/getDefaultOptions')

    //Lê os scripts da default_options
    if(Array.isArray(store.state.config.default_options.codigo_extra_site)) {
      store.state.config.default_options.codigo_extra_site.forEach((plugin) => {
        if (plugin.ativo) {
          if (plugin.scripts.length) {
            plugin.scripts.forEach((scriptUrl) => {
              let script = document.createElement('script')
              script.setAttribute('src', scriptUrl)
              script.async = true
              document.head.appendChild(script)
            })
          }
          if (plugin.codigos.length) {
            plugin.codigos.forEach((codigo) => {
              let script = document.createElement('script')
              script.innerHTML = codigo;
              document.head.appendChild(script)
            })
          }
        }
      });
    }
  }
}
