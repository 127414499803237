<template>
  <div
    id="classroom"
    ref="wrapper"
    :class="[{'nav-open': sidebarOpened}, theme]"
    class="wrapper">
    <aside>
      <nt-classroom-sidebar
        v-if="syllabus"
        :syllabus="syllabus"
        :course-name="courseName"
        :active-lesson="activeLesson">
        <div slot="content">
          <div>
            <img
              :src="company ? rootDomain + company + '_logo.png' : rootDomain + 'logo_unidade.png'"
              alt="">
            <div class="mt-3 mx-1 d-flex flex-column justify-content-center">
              <nuxt-link
                :to="{ name: 'index' }"
                tag="button"
                class="btn btn-primary">
                <i class="fa fa-home"/>
                Voltar ao Ambiente do Aluno
              </nuxt-link>
            </div>
            <hr class="nt-separator my-2">
          </div>
        </div>
      </nt-classroom-sidebar>
    </aside>
    <div
      class="main--panel"
      @click="closeSidebar">
      <nt-top-navbar
        is-classroom
        @logout="logout"/>
      <div class="content mx-auto w-100">
        <nuxt-child/>
      </div>
    </div>
    <nt-footer/>
  </div>
</template>

<script>
import ClassroomSidebar from '@/components/layout/classroom/Sidebar.vue'
import { mapGetters } from 'vuex'
import Footer from '@/components/layout/Footer.vue'
import TopNavBar from '@/components/layout/TopNavBar.vue'
import topBarColors from '@/components/utils/themeColors'

export default {
  middleware: 'authenticated',
  components: {
    'nt-classroom-sidebar': ClassroomSidebar,
    'nt-top-navbar': TopNavBar,
    'nt-footer': Footer
  },
  head () {
    return {
      title: 'Platneb EaD - ' + this.pageTitle,
      meta: [
        { name: 'theme-color', content: topBarColors[this.theme]['frameTopColor'] },
        { name: 'msapplication-navbutton-color', content: topBarColors[this.theme]['frameTopColor'] },
        { name: 'apple-mobile-web-app-status-bar-style', content: topBarColors[this.theme]['frameTopColor'] }
      ]
    }
  },
  computed: {
    ...mapGetters({
      theme: 'app/theme',
      pageTitle: 'app/pageTitle',
      syllabus: 'classroom/syllabus',
      courseName: 'classroom/courseName',
      activeLesson: 'classroom/activeLesson'
    }),
    sidebarOpened () {
      return this.$store.state.app.sidebar.open
    },
    company () {
      return process.env.company;
    },
    rootDomain () {
      return process.env.rootDomain;
    }
  },
  mounted () {
    this.$store.commit('app/setPageTitle', 'Sala de Aula')
  },
  methods: {
    logout: function () {
      this.$store.dispatch('app/logout').then((response) => {
        location.href = '/login'
      })
    },
    closeSidebar () {
      this.$store.commit('app/toggleSidebar', false)
    }
  }
}
</script>
